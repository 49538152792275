const _q = Utils.getParamValue('q');
const _query = Utils.getParamValue('query');

const q = _q ? decodeURIComponent(_q) : null; // 검색게시글뷰에서 사용
const query = _query ? decodeURIComponent(_query) : null; // 검색결과 목록에서 사용

class CafeTiara {
	constructor(section, sectionName, page, pageName, custom = {}) {
		if (!this.isDefined()) return;

		this.tiaraInstance = TiaraTracker.getInstance();
		this.prefix = `${sectionName}>${pageName}`;
		this.trackPage({ section, sectionName, page, custom });
		this.startTime = new Date().getTime();
	}

	isDefined() {
		return typeof TiaraTracker !== 'undefined';
	}

	trackPage({ section, sectionName, page, custom = {} }) {
		this.tiaraInstance.setSvcDomain('m.cafe.daum.net')
			.setSection(section)
			.setUrl(location.href)
			.setPage(page)
			.setSessionTimeout('1800')
			.setDeployment(CAFEAPP.IS_DEV === 'true' ? 'dev' : 'production')
			.setIgnoreQueryParams(['ts', 'dummy'])
			.trackPage(page)
			.customProps(this.generateProps(custom));

		if(query) {
			this.tiaraInstance
				.actionKind('ViewSearchResults')
				.search({
					search_type: 'common',
					search_term: query,
				});
		}

		this.tiaraInstance.track();

		const trackUsage = () => {
			const scrollHeight = document.body.scrollHeight;
			const scrollY = window.scrollY;
			this.tiaraInstance.trackUsage()
				.usage({
					duration: new Date().getTime() - this.startTime,
					scroll_percent: Math.round((scrollY + scrollHeight) / scrollHeight * 100),
					scroll_height: scrollHeight,
					scroll_top: scrollY,
					scroll_inner_height: window.innerHeight,
					scroll_bottom: scrollY + scrollHeight
				})
				.track();
		};
		window.onbeforeunload = trackUsage;
		if (Utils.isiPad() || Utils.isiPhone()) {
			window.onpagehide = trackUsage;
		}
	}

	trackEvent({ layer, desc, url='', custom = {} }) {
		if (!this.isDefined()) return;

		this.tiaraInstance.trackEvent(`${this.prefix}>${desc}`)
			.click({
				layer1: layer,
				layer2: '',
				layer3: '',
				click_url: url.slice(0, 255),
				copy: '',
				posx: 0,
				posy: 0
			})
			.customProps(this.generateProps(custom))
			.track();
	}
	
	trackUserActionEvent({ page, pageName, layer, layerName, url, custom = {} }) {
		if (!this.isDefined()) return;
		
		this.tiaraInstance
			.setSection('user_action')
			.setPage(page)
			.trackEvent(`유저액션_${pageName}_${layerName}`)
			.click({
				layer1: layer,
				layer2: '',
				layer3: '',
				click_url: url.slice(0, 255),
				copy: '',
				posx: 0,
				posy: 0
			})
			.customProps(this.generateProps(custom))
			.track();
	}
	
	trackViewableImpression({page, pageName, layerName, viewImpContents}) {
		if (!this.isDefined()) return;
		
		this.tiaraInstance.setSection('user_action')
			.setPage(page)
			.trackViewImp(`유저액션_${pageName}_${layerName}`)
			.viewImpContents(viewImpContents)
			.track();
	}

	generateProps(custom) {
		return {
			...(CAFEAPP.GRPCODE ? { grpcode: CAFEAPP.GRPCODE, grpname: CAFEAPP.GRPNAME, grpid: CAFEAPP.GRPID } : {}),
			...(CAFEAPP.FLDID ? { fldid: CAFEAPP.FLDID } : {}),
			...(CAFEAPP.DATAID ? { dataid: CAFEAPP.DATAID } : {}),
			...(Utils.isGameSDK() && CAFEAPP.IS_GAMECAFE === 'true' ? { gamecafe: true } : {}),
			...(q ? { query: q } : {}),
			...custom,
		};
	}
}

export default CafeTiara
