import $ from 'jquery';

import CafeTiara from '@src/tiara-tracker';

window.CafeTiara = CafeTiara; // 게임카페에서 사용중

window.registerTiara = (key, option) => {
	const registerCommon = cafeTiara => {
		//
	};

	const registerTopCommon = cafeTiara => {
		$('#cafeCreateBtn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'cafe_create_btn', desc: '카페만들기 버튼' });
		});
		$('#btn_cafe_search').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'all_search_btn', desc: '전체 검색 버튼' });
		});
		$('#cafeCreateBanner').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'cafe_create_banner', desc: '카페만들기 배너' });
		});
		$('.area-event').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'promotion_banner', desc: '탑 배너' });
		});
	};

	const registerProfile = cafeTiara => {
		const daumHead = $('#daumHead');

		daumHead.on('mousedown', '.btn_alim', e => {
			cafeTiara.trackEvent({ layer: 'keyword_setting_btn', desc: '키워드알림 설정' });
		});
		$('#btn_cafe_profile').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'cafe_profile_btn', desc: '카페 프로필 보기' });
		});
		$('#my-articles').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'member_profile_btn', desc: '내가 쓴 글' });
		});
		$('#setting-btn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'cafe_setting_btn', desc: '설정' });
		});

		daumHead.on('mousedown', '.cheer_button', e => {
			cafeTiara.trackEvent({ layer: 'cheerup_btn', desc: '응원하기' });
		}).on('mousedown', '.visual_link_calendar', e => {
			cafeTiara.trackEvent({ layer: 'calendar_shortcut', desc: '스케줄 바로가기', url: e.currentTarget.href });
		}).on('mousedown', '.cheer_rank', e => {
			cafeTiara.trackEvent({ layer: 'fandom_rank', desc: '팬덤랭킹', url: e.currentTarget.href });
		});

		$('#cafe_navi_default').on('mousedown', '.write_cafe_btn', e => {
			cafeTiara.trackEvent({ layer: 'write_btn', desc: '글쓰기' });
		});

		$('#commonInviteLink').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'invite_btn', desc: '개별카페홈 상단 > 카페초대 링크'});
		});
		$('.list_share2').on('mousedown', '#share2_talk', e => {
			cafeTiara.trackEvent({ layer: 'kakaotalk_btn', desc: '개설완료 화면 > 카카오톡 공유하기' });
		}).on('mousedown', '#share2_link', e => {
			cafeTiara.trackEvent({ layer: 'linkcopy_btn', desc: '개설완료 화면 > 초대링크 복사하기' });
		}).on('mousedown', '#share2_mms', e => {
			cafeTiara.trackEvent({ layer: 'text_btn', desc: '개설완료 화면 > 문자로 공유하기' });
		});
		$('#copyurl_layer .txt_copy').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'urlcopy_btn', desc: '초대하기 화면 > 초대하기 > 초대링크 복사하기 > url 복사' });
		});
	};

	const registerEditor = cafeTiara => {
		$('#me_editor').on('click', '.btn_img', e => {
			cafeTiara.trackEvent({ layer: 'img_add_btn', desc: '이미지 첨부' });
		});
		$('#btnAttachMulti').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'imgs_add_btn', desc: '이미지 여러장 첨부' });
		});
		$('#btnImageOrder').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'img_ordering_btn', desc: '순서변경' });
		});
	};

	const registerBoard = cafeTiara => {
		$('#board_refesh').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'refresh_btn', desc: '새로고침' });
		});
		$('#boardKeywordSetting').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'keyword_setting_btn', desc: '키워드알림설정' });
		});
		$('#articleManageBtn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'article_management_btn', desc: '삭제화면 이동' });
		});
		$('#boardNoticeYn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'notice_fold_btn', desc: '공지 접기,펴기' });
		});
		$('#select_headcont').on('change', e => {
			cafeTiara.trackEvent({ layer: 'subject_option', desc: '말머리 선택' });
		});
	};

	const registerComment = cafeTiara => {
		$('#me_editor').on('click', '.me_uf_input_file', e => {
			cafeTiara.trackEvent({ layer: 'img_add_btn', desc: '이미지 첨부' });
		});
		$('#btnAttachSticker').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'emoticon_add_btn', desc: '이모티콘 첨부' });
		});
		$('#hiddenyn').on('change', e => {
			cafeTiara.trackEvent({ layer: 'secret_btn', desc: '비밀댓글' });
		});
		$('#commentList').on('mousedown', '.comment_report_clean_btn', e => {
			cafeTiara.trackEvent({ layer: 'comment_report_clean_btn', desc: '클린센터신고' });
		}).on('mousedown', '.comment_report_admin_btn', e => {
			cafeTiara.trackEvent({ layer: 'comment_report_report_btn', desc: '카페운영자제보' });
		});
	};

	const registerArticle = (cafeTiara, custom = {}) => {
		$('#mArticle').on('mousedown', '.txc-image', e => {
			cafeTiara.trackEvent({ layer: 'image', desc: '이미지 클릭', custom });
		});
	};

	const registerGeneralArticle = (cafeTiara, custom = {}) => {
		$('#btnWeb2App').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'cafe_app_btn', desc: '앱으로보기', custom });
		});
		$('#mArticle').on('mousedown', '.button-return-list', e => {
			cafeTiara.trackEvent({ layer: 'article_list_btn', desc: '목록 버튼', custom });
		}).on('mousedown', '.link_cmt', e => {
			cafeTiara.trackEvent({ layer: 'comment_view_btn', desc: '전체댓글보기 버튼', custom });
		}).on('mousedown', '.link_all', e => {
			cafeTiara.trackEvent({ layer: 'comment_view_banner', desc: '댓글 전체보기', custom });
		});
		$('#zoomin/*, #zoomout*/').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'up_fontsize_btn', desc: '글자크기 키우기 버튼', custom });
		});
	};

	const registerTodayBubble = (cafeTiara, custom = {}) => {
		$('.today_bubble').on('click', '.btn_help', e => {
			cafeTiara.trackEvent({ layer: 'todaybubble_info', desc: '투데이버블도움말' });
		}).on('click', '.link_trendkey', e => {
			cafeTiara.trackEvent({ layer: 'todaybubble_keyword', desc: '투데이버블개별키워드', url: e.currentTarget.href });
		}).on('click', '.link_extend', e => {
			cafeTiara.trackEvent({ layer: 'todaybubble_refresh', desc: '투데이버블새로운키워드' });
		});
	};

	const registerMoreButton = (cafeTiara, custom = {}) => {
		$('#bookmarkToggleBtn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'bookmark.btn', desc: '북마크', custom });
		});
		$('#socialShareContainer').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'article_share_btn', desc: '공유하기', custom });
		});
		$('#report_clean_btn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'report_clean_btn', desc: '클린센터신고', custom });
		});
		$('#report_admin_btn').on('mousedown', e => {
			cafeTiara.trackEvent({ layer: 'report_admin_btn', desc: '카페운영자제보', custom });
		});
	};

	const getApplySection = () => {
		const isLevelUp = option === 'levelUp';
		return {
			section: isLevelUp ? 'level' : 'apply',
			sectionName: isLevelUp ? '등업게시판' : '참여게시판',
		};
	};

	const startTime = Date.now();
	let daumTopObj = cafe.util.getCookie('DAUMTOP');
	let torosCustomProps = {};
	let trackerSent = false;
	const setUsage = () => {
		const cafekey = "cafe-" + CAFEAPP.GRPCODE + "/" + CAFEAPP.FLDID + "/" + CAFEAPP.DATAID;
		try {
			daumTopObj = JSON.parse(daumTopObj);
			if(daumTopObj.contents_unique_key === cafekey) {
				torosCustomProps = {
					"toros_imp_id": daumTopObj.impression_id,
					"toros_file_hash_key": daumTopObj.toros_file_hash_key,
					"toros_user_id_type": "uuid",
					"toros_page_meta_id": daumTopObj.contents_unique_key,
					"contentUniqueKey": daumTopObj.contents_unique_key
				};
				cafe.util.deleteCookie('DAUMTOP');
			}
		} catch(e) {}
	};
	const setUsageEvents = () => {
		if ("onpagehide" in window) {
			window.addEventListener("pagehide", () => sendUsage(), false);
		} else {
			window.addEventListener("unload", () => sendUsage(), false);
		}
		document.addEventListener("visibilitychange", () => {
			document["hidden"] && sendUsage();
		}, false);
	};
	
	const sendUsage = () => {
		if(trackerSent)
			return;

		trackerSent = true;
		TiaraTracker.getInstance()
					.trackPage('mTop toros')
					.setSvcDomain('m.cafe.daum.net')
					.setSection('top')
					.setPage('카페탑')
					.trackUsage()
					.usage({ duration: Date.now() - startTime })
					.customProps(torosCustomProps)
					.track();
	};
	
	const sendTablePostUserAction = (cafeTiara, currentTarget) => {
		const page = Utils.getParamValue('page') || '1';
		const {tableId, postId} = currentTarget.dataset;
		
		if (!tableId || !postId || isNaN(Number(page))) {
			return;
		}
		
		const postOrder = $(currentTarget).closest('li').index() + (Number(page) - 1) * 20;
		const custom = { tableId, postId, ordnum: postOrder, ref: 'unified_search' };
		cafeTiara.trackUserActionEvent({ page: 'unified_search', pageName: '카페통합검색', layer: 'enter_post_view', layerName: '게시글뷰인입버튼', url: currentTarget.href, custom });
	};
	
	const tiaraMap = {
		top() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'recommend_page', '추천');

			$('#slideCafeToday').on('mousedown', '.link_slide', e => {
				cafeTiara.trackEvent({ layer: 'recommend_cafe', desc: '추천카페', url: e.currentTarget.href });
			});
			$('#hit_rank').on('mousedown', '.link_hit', e => {
				cafeTiara.trackEvent({ layer: 'hot_article_title', desc: '인기글 목록', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		popularView() {
			const svc = Utils.getParamValue('svc');
			const custom = { ...(svc ? { svc } : {}) };
			const cafeTiara = new window.CafeTiara('top', '카페탑', 'hot_article_view', '인기글뷰', custom);

			if (svc === 'topRank') {
				$('#daumHead').on('click', '.link_visit', e => {
					cafeTiara.trackEvent({ layer: 'cafe_visit_btn', desc: '카페방문 버튼', url: e.currentTarget.href });
				});
				$('#mArticle').on('mousedown', '.view_cmt .link_all', e => {
					cafeTiara.trackEvent({ layer: 'cafe_visit_banner', desc: '카페 방문해 더 많은 댓글 만나기' });
				});
				$('#linkageContents').on('mousedown', '.link_group', e => {
					cafeTiara.trackEvent({ layer: 'prev_next_article', desc: '다른 실시간 인기글', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_detail', e => {
					cafeTiara.trackEvent({ layer: 'table_home', desc: '테이블 홈', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_item', e => {
					cafeTiara.trackEvent({ layer: 'table_post', desc: '테이블 글', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_more', e => {
					cafeTiara.trackEvent({ layer: 'tablepost_more_btn', desc: '…버튼', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.view_group .link_group', e => {
					cafeTiara.trackEvent({ layer: 'more_article_title', desc: '다른실시간인기글리스트', url: e.currentTarget.href });
				});
				registerGeneralArticle(cafeTiara, custom);
				registerTodayBubble(cafeTiara, custom);
			} else {
				$('#cafeVisitBtn').on('click', e => {
					cafeTiara.trackEvent({ layer: 'cafe_visit_btn', desc: '카페방문 버튼', url: e.currentTarget.href });
				});
				$('#mArticle').on('mousedown', '.thumb_hotview, .desc_hotview', e => {
					cafeTiara.trackEvent({ layer: 'hot_list_weekly', desc: '지난주 카페 인기글', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_detail', e => {
					cafeTiara.trackEvent({ layer: 'table_home', desc: '테이블 홈', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_item', e => {
					cafeTiara.trackEvent({ layer: 'table_post', desc: '테이블 글', url: e.currentTarget.href });
				});
				$('#mArticle').on('click', '.today_table .link_more', e => {
					cafeTiara.trackEvent({ layer: 'tablepost_more_btn', desc: '…버튼', url: e.currentTarget.href });
				});
				if (svc === 'daumapp') {
					$('#mArticle').on('click', '.link_visit', () => {
						cafeTiara.trackEvent({ layer: 'footer_banner', desc: '하단배너' });
					});	
				}
			}

			$('#mArticle').on('click', '.view_card .thumb_hotview', e => {
				cafeTiara.trackEvent({ layer: 'hot_list_weekly', desc: '지난주인기글', url: e.currentTarget.href });
			});

			if(daumTopObj) {
				setUsage();
				setUsageEvents();
			}

			registerCommon(cafeTiara);
			registerArticle(cafeTiara, custom);
			registerMoreButton(cafeTiara, custom);
		},
		fanCafe() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'fan_cafe', '팬카페');

			$('#fanCafeSortOption').on('mousedown', '.fancafe-tab-button', e => {
				cafeTiara.trackEvent({ layer: 'cafe_sorting_option', desc: '팬카페 정렬' });
			});
			$('#fandom, #cafe, #cheer').on('mousedown', '.rank_list-link', e => {
				cafeTiara.trackEvent({ layer: 'fan_cafe_title', desc: '카페 목록', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		myCafe() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'my_cafe', '내카페');

			$('#favCafeList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'favorite_cafe_title', desc: '즐겨찾는 카페 목록', url: e.currentTarget.href });
			});
			$('#allCafeList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'my_cafe_title', desc: '내 전체 카페 목록', url: e.currentTarget.href });
			});
			$('#favCafeList, #allCafeList').on('mousedown', '.btn_favorite', e => {
				cafeTiara.trackEvent({ layer: 'cafe_favorite_btn', desc: '카페 즐겨찾기 버튼' });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		favBoards() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'my_cafe', '내카페');

			$('#favFolderList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'favorite_board_title', desc: '즐겨찾는 게시판 목록', url: e.currentTarget.href });
			}).on('mousedown', '.alim_setting', e => {
				cafeTiara.trackEvent({ layer: 'board_favorite_btn', desc: '게시판 즐겨찾기 버튼' });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		bookmarks() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'my_cafe', '내카페');

			$('#mArticle').on('mousedown', '.btn_tag', e => {
				cafeTiara.trackEvent({ layer: 'tag_fold_btn', desc: '태그 접기,펴기' });
			}).on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'bookmark_article_title', desc: '북마크 게시글 목록', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		activityNews() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'my_noti', '내소식');
			$('#daumapp_setting').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'noti_setting_btn', desc: '알림설정 버튼' });
			});
			$('#myNewsList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'activity_noti_list', desc: '카페활동알림 목록', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		newArticleNews() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'my_noti', '내소식');
			$('#daumapp_setting').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'noti_setting_btn', desc: '알림설정 버튼' });
			});
			$('#myNewsList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'new_noti_list', desc: '새글알림 목록', url: e.currentTarget.href });
			});
			$('#mArticle').on('mousedown', '.keyword_link', e => {
				cafeTiara.trackEvent({ layer: 'keyword_setting_btn', desc: '키워드알림 편집 버튼' });
			});

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		keyword() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'keyword_noti', '키워드알림설정');
			$('#addAlimBtn').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'keyword_add_btn', desc: '키워드 등록 버튼' });
			});
			registerCommon(cafeTiara);
		},
		cafesOfCategory() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'category_page', '카테고리별 카페리스트');

			registerCommon(cafeTiara);
			registerTopCommon(cafeTiara);
		},
		allSearch() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'all_search', '카페검색');
			const isNameSearch = location.pathname.includes('name');

			const setTrackPostsViewableImpression = () => {
				if (isNameSearch || IntersectionObserver === undefined) {
					return;
				}

				const page = Utils.getParamValue('page') || '1';
				const viewImpContents = [];
				
				const intersectionObserver = new IntersectionObserver((entries, observer) => {
					entries.forEach(function (entry) {
						if (!entry.isIntersecting) {
							return;
						}

						observer.unobserve(entry.target);
						const title = $(entry.target).find('.tit_cafe')[0].innerText.slice($(entry.target).find('.tit_cafe .tit_sub')[0].innerText.length + 1);
						
						viewImpContents.push({
							layer1: 'post_feed',
							imp_extra_info: JSON.stringify({
								tableId: Number($(entry.target).attr('data-table-id')),
								postId: $(entry.target).attr('data-post-id'),
								title: title,
								ordnum: $(entry.target).closest('li').index() + (Number(page) - 1) * 20
							})
						});
					});
				}, {threshold: 1});

				$('#slideArticleList a').each(function (index, element) {
					if ($(element).data('serviceType') === 'cafe') {
						return;
					}

					intersectionObserver.observe(element);
				});
				
				$(window).on('beforeunload', () => {
					if (viewImpContents.length === 0) {
						return;
					}

					cafeTiara.trackViewableImpression({
						page: 'unified_search',
						pageName: '통합검색결과페이지피드',
						layerName: '게시글피드',
						viewImpContents: viewImpContents
					});
				});
			};

			$('#cafenameFilterBtn').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'cafe_sorting_option', desc: '카페명 검색결과 정렬' });
			});
			$('#articleFilterBtn').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'article_sorting_option', desc: '카페글 검색결과 정렬' });
			});
			$('#slideArticleList').on('mousedown', '.link_cafe', e => {
				if (e.currentTarget.dataset.serviceType === 'cafe') {
					if (isNameSearch)
						cafeTiara.trackEvent({ layer: 'search_cafe_title', desc: '카페명 검색결과 목록', url: e.currentTarget.href });
					else
						cafeTiara.trackEvent({ layer: 'search_article_title', desc: '카페글 검색결과 목록', url: e.currentTarget.href });
					return;
				}
				
				if (isNameSearch) {
					cafeTiara.trackEvent({layer: 'search_table_title', desc: '테이블명 검색결과 목록', url: e.currentTarget.href });
					return;
				}
				
				cafeTiara.trackEvent({layer: 'search_post_title', desc: '테이블글 검색결과 목록', url: e.currentTarget.href });
				sendTablePostUserAction(cafeTiara, e.currentTarget);
			});

			$('#search_daum').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'daum_search_btn', desc: '통합검색 결과보기' });
			});

			$(window).on('load', () => {
				setTrackPostsViewableImpression();
			});
			registerCommon(cafeTiara);
		},
		searchView() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'search_article_view', '검색게시글뷰');
			
			$('.viewrecom_wrap').on('mousedown', '.search_widget .search_link', e => {
				cafeTiara.trackEvent({ layer: 'more_search_article_title', desc: '관련카페글', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerArticle(cafeTiara);
			registerGeneralArticle(cafeTiara);
			registerMoreButton(cafeTiara);
		},
		cafeCreate() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'cafe_create', '카페 만들기');

			$('#cafe_make_result_link').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'cafe_visit_btn', desc: '개설카페이동', url: e.currentTarget.href });
			});
			$('#cafe_make_result_fav').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'cafe_favorite_btn', desc: '즐겨찾는 카페에 추가' });
			});

			registerCommon(cafeTiara);
		},
		agreement() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'agreement_page', '카페약관');

			registerCommon(cafeTiara);
		},
		privacyAgreement() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'privacy_agreement', '개인정보보호의무');

			registerCommon(cafeTiara);
		},
		share() {
			const cafeTiara = new CafeTiara('top', '카페탑', 'daumcafe_share', '다음카페로 공유하기');

			registerCommon(cafeTiara);
		},

		cafeHome() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'cafe_home', '개별 카페 홈');

			$('#favBoardList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'favorite_board_title', desc: '즐겨찾는 게시판 목록', url: e.currentTarget.href });
			});
			$('#boardList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'all_board_title', desc: '전체 게시판 목록', url: e.currentTarget.href });
			});

			$('#favBoardList, #boardList').on('mousedown', '.alim_setting', e => {
				cafeTiara.trackEvent({ layer: 'board_favorite_btn', desc: '즐겨찾기 버튼' });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
		},
		allArticles() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'all_article', '전체글');

			$('#cafe_list_select').on('change', e => {
				cafeTiara.trackEvent({ layer: 'article_sorting_option', desc: '전체글 정렬' });
			});
			$('#slideArticleList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'article_title', desc: '전체글 목록', url: e.currentTarget.href });
			}).on('mousedown', '.link_cmt', e => {
				cafeTiara.trackEvent({ layer: 'comment_view_btn', desc: '댓글보기 버튼', url: e.currentTarget.href });
			});

			$('#article-delete-btn').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'article_management_btn', desc: '삭제화면 이동' });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
		},
		images() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'img_article', '이미지');

			$('#cafe_list_select').on('change', e => {
				cafeTiara.trackEvent({ layer: 'board_select_option', desc: '이미지탭 게시판 선택' });
			});
			$('#container').on('mousedown', '.link_image', e => {
				cafeTiara.trackEvent({ layer: 'article_title', desc: '이미지탭 목록', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
		},
		cafeJoin() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'cafe_join', '가입');

			registerCommon(cafeTiara);
		},
		myArticles() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'member_profile', '내가 쓴글');

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
		},
		cafeSearch() {
			const cafeTiara = new CafeTiara('cafe', '개별카페', 'cafe_search', '카페 내 검색');

			$('#sortType').on('change', e => {
				cafeTiara.trackEvent({ layer: 'article_sorting_option', desc: '카페글 검색결과 정렬' });
			});

			$('#slideArticleList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'search_article_title', desc: '카페글 검색결과 목록', url: e.currentTarget.href });
			});
			$('#other-board-search').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'other_search_btn', desc: '다른 게시판 결과보기' });
			});
			$('#daum-search').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'daum_search_btn', desc: '통합검색 결과보기' });
			});

			registerCommon(cafeTiara);
		},
		write() {
			const cafeTiara = new CafeTiara('write', '카페글쓰기', 'write_page', '글쓰기');

			registerCommon(cafeTiara);
			registerEditor(cafeTiara);
		},
		modify() {
			const cafeTiara = new CafeTiara('write', '카페글쓰기', 'modify_page', '글수정');

			registerCommon(cafeTiara);
			registerEditor(cafeTiara);
		},
		reply() {
			const cafeTiara = new CafeTiara('write', '카페글쓰기', 'reply_page', '답글쓰기');

			registerCommon(cafeTiara);
			registerEditor(cafeTiara);
		},
		joinManagement() {
			const cafeTiara = new CafeTiara('management', '관리하기', 'join_approval', '가입신청 회원관리');

			registerCommon(cafeTiara);
		},

		generalArticleList() {
			const cafeTiara = new CafeTiara('general', '일반게시판', 'article_list', '게시글 리스트');

			$('#slideArticleList').on('mousedown', '.link_cafe', e => {
				cafeTiara.trackEvent({ layer: 'article_title', desc: '글목록 영역 클릭', url: e.currentTarget.href });
			}).on('mousedown', '.link_cmt', e => {
				cafeTiara.trackEvent({ layer: 'comment_view_btn', desc: '댓글보기', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
			registerBoard(cafeTiara);
		},
		generalArticleView() {
			const cafeTiara = new CafeTiara('general', '일반게시판', 'article_view', '게시글 뷰');

			$('#linkageContents').on('mousedown', '.link_group', e => {
				cafeTiara.trackEvent({ layer: 'more_article_title', desc: '게시판 다른글', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerArticle(cafeTiara);
			registerGeneralArticle(cafeTiara);
			registerMoreButton(cafeTiara);
		},
		generalCommentList() {
			const cafeTiara = new CafeTiara('general', '일반게시판', 'comment_list', '댓글 목록');

			registerCommon(cafeTiara);
			registerComment(cafeTiara);
		},
		generalCommentWrite() {
			const cafeTiara = new CafeTiara('general', '일반게시판', 'comment_write', '댓글쓰기');

			registerCommon(cafeTiara);
			registerComment(cafeTiara);
		},

		albumArticleList() {
			const cafeTiara = new CafeTiara('album', '앨범게시판', 'article_list', '게시글 리스트');

			$('#container').on('mousedown', '.link_image', e => {
				cafeTiara.trackEvent({ layer: 'article_title', desc: '글목록 영역 클릭', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
			registerBoard(cafeTiara);
		},
		albumArticleView() {
			const cafeTiara = new CafeTiara('album', '앨범게시판', 'article_view', '게시글 뷰');

			$('#linkageContents').on('mousedown', '.link_group', e => {
				cafeTiara.trackEvent({ layer: 'more_article_title', desc: '게시판 다른글', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
			registerArticle(cafeTiara);
			registerGeneralArticle(cafeTiara);
			registerMoreButton(cafeTiara);
		},
		albumCommentList() {
			const cafeTiara = new CafeTiara('album', '앨범게시판', 'comment_list', '댓글 목록');

			registerCommon(cafeTiara);
			registerComment(cafeTiara);
		},
		albumCommentWrite() {
			const cafeTiara = new CafeTiara('album', '앨범게시판', 'comment_write', '댓글쓰기');

			registerCommon(cafeTiara);
			registerComment(cafeTiara);
		},

		memoArticleList() {
			const cafeTiara = new CafeTiara('memo', '한줄메모장', 'article_list', '게시글 리스트');

			$('#slideArticleList').on('mousedown', '.btn_reply', e => {
				cafeTiara.trackEvent({ layer: 'comment_view_btn', desc: '답글(댓글)보기', url: e.currentTarget.href });
			}).on('click', '.link_img', e => {
				cafeTiara.trackEvent({ layer: 'thumb', desc: '썸네일 이미지', url: e.currentTarget.children[0].src });
			});

			registerCommon(cafeTiara);
			registerProfile(cafeTiara);
			registerBoard(cafeTiara);
		},
		memoCommentList() {
			const cafeTiara = new CafeTiara('memo', '한줄메모장', 'comment_list', '댓글 목록');

			registerCommon(cafeTiara);
			registerComment(cafeTiara);
		},

		applyArticleList() {
			const { section, sectionName } = getApplySection();
			const cafeTiara = new CafeTiara(section, sectionName, 'article_list', '게시글 리스트');

			$(document).on('mousedown', '#btn_noti_fold', e => {
				cafeTiara.trackEvent({ layer: 'notice_fold_btn', desc: '공지 접기,펴기' });
			}).on('mousedown', '#btn_apply_stop', e => {
				cafeTiara.trackEvent({ layer: 'end_btn', desc: '참여 종료' });
			});

			registerCommon(cafeTiara);
		},
		applyArticleView() {
			const { section, sectionName } = getApplySection();
			const cafeTiara = new CafeTiara(section, sectionName, 'article_view', '게시글 뷰');
			$('#cafe_navi_default').on('mousedown', '.apply_delete', e => {
				cafeTiara.trackEvent({ layer: 'delete_btn', desc: '삭제하기' });
			}).on('mousedown', '.apply_modify', e => {
				cafeTiara.trackEvent({ layer: 'modify_btn', desc: '수정하기' });
			});

			registerCommon(cafeTiara);
		},
		applyWrite() {
			const { section, sectionName } = getApplySection();
			const cafeTiara = new CafeTiara(section, sectionName, 'write_page', '글작성', { type: 'new' });

			registerCommon(cafeTiara);
		},
		applyEdit() {
			const { section, sectionName } = getApplySection();
			const cafeTiara = new CafeTiara(section, sectionName, 'write_page', '글작성', { type: 'edit' });

			registerCommon(cafeTiara);
		},

		scheduleList() {
			const cafeTiara = new CafeTiara('calendar', '스타스케줄', 'article_list', '게시글 리스트');

			$(document).on('mousedown', '.cal_fold_btn', e => {
				cafeTiara.trackEvent({ layer: 'calendar_fold_btn', desc: '캘린더 접기,펴기' });
			}).on('mousedown', '.ico_alarm', e => {
				cafeTiara.trackEvent({ layer: 'board_noti_btn', desc: '알림 설정' });
			}).on('change', '.select_calendar', e => {
				cafeTiara.trackEvent({ layer: 'subject_option', desc: '말머리 선택' });
			}).on('mousedown', '.btn_today', e => {
				cafeTiara.trackEvent({ layer: 'today_btn', desc: '오늘' });
			}).on('mousedown', '.btn_select', e => {
				cafeTiara.trackEvent({ layer: 'day', desc: '특정 날짜 클릭' });
			}).on('mousedown', '.schedule_link', e => {
				cafeTiara.trackEvent({ layer: 'schedule', desc: '개별스케줄', url: e.currentTarget.href });
			});

			registerCommon(cafeTiara);
		},
		scheduleDetail() {
			const cafeTiara = new CafeTiara('calendar', '스타스케줄', 'article_view', '게시글 뷰');

			registerCommon(cafeTiara);
		},
		scheduleWrite() {
			const cafeTiara = new CafeTiara('calendar', '스타스케줄', 'write_page', '글작성');

			$(document).on('change', '#pushyn', e => {
				cafeTiara.trackEvent({ layer: 'noti_option', desc: '알림 발송 선택' });
			});

			registerCommon(cafeTiara);
		},
		cafeCreateResult() {
			const cafeTiara = new CafeTiara('inviteLink', '링크 초대하기', 'cafe_create', '개설 완료');
			$('.list_share').on('mousedown', '#share_talk', e => {
				cafeTiara.trackEvent({ layer: 'kakaotalk_btn', desc: '개설완료 화면 > 카카오톡 공유하기' });
			}).on('mousedown', '#share_link', e => {
				cafeTiara.trackEvent({ layer: 'linkcopy_btn', desc: '개설완료 화면 > 초대링크 복사하기' });
			}).on('mousedown', '#share_mms', e => {
				cafeTiara.trackEvent({ layer: 'text_btn', desc: '개설완료 화면 > 문자로 공유하기' });
			});

			$('.wrap_btn').on('mousedown', 'a:first-child', e => {
				cafeTiara.trackEvent({ layer: 'mycafe_btn', desc: '개설완료 화면 > 내 카페 바로가기' });
			}).on('mousedown', 'a:last-child', e => {
				cafeTiara.trackEvent({ layer: 'addfavortiecafe_btn', desc: '개설완료 화면 > 자주가는 카페 추가하기' });
			});
		},
		cafeInviteJoin() {
			const cafeTiara = new CafeTiara('inviteLink', '링크 초대하기', 'invitation_letter', '초대장');
			$('#btnJoinSubmit').on('mousedown', e => {
				cafeTiara.trackEvent({ layer: 'join_btn', desc: '초대장 > 가입하기 버튼'});
			});
		},
		reportAdmin() {
			const cafeTiara = new CafeTiara('reportToAdmin', '카페운영자제보', 'report_to_admin', '카페운영자제보');
			$('#daumWrap').on('mousedown', '.confirm_button', (e) => {
				cafeTiara.trackEvent({ layer: 'confirm', desc: '카페운영자제보 > 제보하기'});
			}).on('mousedown', '.prev_button', (e) => {
				cafeTiara.trackEvent({ layer: 'cancel', desc: '카페운영자제보 > 취소'});
			});
		},
		error400() {
			new CafeTiara('error403', '카페탑', 'error_page', 'error');
		},
		error403() {
			new CafeTiara('error403', '카페탑', 'error_page', 'error');
		},
		error404() {
			new CafeTiara('error404', '카페탑', 'error_page', 'error');
		},
		error500() {
			new CafeTiara('error500', '카페탑', 'error_page', 'error');
		},
		status403() {
			new CafeTiara('status403', '카페탑', 'status_page', 'status');
		},
		status404() {
			new CafeTiara('status404', '카페탑', 'status_page', 'status');
		},
		status500() {
			new CafeTiara('status500', '카페탑', 'status_page', 'status');
		},
		status500Maintenance() {
			new CafeTiara('status500Maintenance', '카페탑', 'status_page', 'status');
		},
	};

	if (key in tiaraMap)
		tiaraMap[key]();
	else throw new Error('Invalid key');
};
